<template>
  <div
    class="wrapper campaigns"
  >
    <div class="title-wrap relative">
      <h3 class="page-titles">
        Campaigns
      </h3>
      <p>Below are the campaigns you have created, you can view results for the campaigns or distribute to candidates. </p>
      <div class="profile">
        <the-profile class="print:hidden" />
      </div>
    </div>
    <div class="flex">
      <div class="mb-8">
        <router-link
          class="btn-secondary create-camp-btn"
          :to="{name: 'CreateCampaign'}"
        >
          Create campaign
        </router-link>
      </div>
    </div>
    <div class="flex flex-col w-full h-full">
      <div class="flex flex-col my-8">
        <campaign-dropdown
          v-for="campaign, index in campaigns"
          :open-latest="index == 0 && !!$route.query.latest"
          :key="campaign.id"
          :campaign="campaign"
        />
      </div>
      <div class="justify-center my-8 btn-group">
        <button
          v-for="page in currentPages[1]"
          :key="page"
          class="btn btn-outline"
          :class="{'btn-active': page === currentPages[0] +1 }"
          @click="nextPage(page-1)"
        >
          {{ page }}
        </button>
      </div>
    </div>
    <input
      type="checkbox"
      id="instructions-modal"
      class="modal-toggle"
    >
    <div class="modal">
      <div class="max-w-6xl modal-box p-8">
        <h3 class="text-lg font-semibold mb-4 ml-4">
          Instructions for testing with the Capacio APP
        </h3>
        <email-template
          :is-questionnaire="false"
          :link="''"
          :modal="true"
        />
        <div class="modal-action">
          <label
            for="instructions-modal"
            class="btn-outline-small"
          >Close</label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CampaignDropdown from '../components/CampaignDropdown.vue'
import { mapGetters } from 'vuex'
import EmailTemplate from '../components/EmailTemplate.vue'
import TheProfile from '@/components/TheProfile.vue'

export default {
  components: { CampaignDropdown, EmailTemplate, TheProfile },
  computed: {
    ...mapGetters({
      campaigns: 'CAMPAIGNS/getCampaigns', currentPages: 'CAMPAIGNS/getPages'
    })
  },
  methods: {
    async nextPage (page) {
      // update query
      this.$router.push({ name: 'Overview', params: { page } })
      this.$store.commit('CAMPAIGNS/SET_CURRENT_PAGE', page)
      await this.$store.dispatch('CAMPAIGNS/getOrganisationCampaigns')
    }
  }
}
</script>
<style>
@import "../campaigns.scss";
@import "../overview.scss";
</style>
