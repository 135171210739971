<template>
  <!-- Email for cognitive tests -->
  <div
    v-if="isQuestionnaire == false"
    contenteditable="false"
    class="p-4 bg-white rounded border-none"
  >
    <p
      class="border-none"
      style="margin-bottom:20px;font-size: 14px; ;"
    >
      <u>Instructions for the test</u>
    </p>
    <p
      class="border-none"
      style="margin-bottom:20px;font-size: 14px;"
    >
      The test examines your cognitive abilities through various tasks, and measures aspects such as speed, accuracy and productivity. The test is very sensitive, so read the instructions below carefully.
    </p>

    <table
      cellspacing="0"
      cellpadding="0"
    >
      <tr>
        <td
          width="20"
          align="center"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          &bull;
        </td>
        <td
          align="left"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          Make sure to understand and carefully follow all instructions given in the test.
        </td>
      </tr>
      <tr>
        <td
          width="20"
          align="center"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          &bull;
        </td>
        <td
          align="left"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          Set aside 50 to 60 minutes for the full test. (10 to 15 minutes for separate test).
        </td>
      </tr><tr>
        <td
          width="20"
          align="center"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          &bull;
        </td>
        <td
          align="left"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          Perform the test in one go, without interruptions or pauses.
        </td>
      </tr>
      <tr>
        <td
          width="20"
          align="center"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          &bull;
        </td>
        <td
          align="left"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          Perform the test well rested, daytime, during your prime time of performance.
        </td>
      </tr>
      <tr>
        <td
          width="20"
          align="center"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          &bull;
        </td>
        <td
          align="left"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          Disturbances will affect your result. Sit in an undisturbed environment.
        </td>
      </tr>      <tr>
        <td
          width="20"
          align="center"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          &bull;
        </td>
        <td
          align="left"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          Be as focused as you can.
        </td>
      </tr>
      <tr>
        <td
          width="20"
          align="center"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          &bull;
        </td>
        <td
          align="left"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          Shut down all apps that are running and swipe them away from your app switcher.
        </td>
      </tr>
      <tr>
        <td
          width="20"
          align="center"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          &bull;
        </td>
        <td
          align="left"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          Use a mobile phone with a touch screen, NOT a computer.
        </td>
      </tr>
      <tr>
        <td
          width="20"
          align="center"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          &bull;
        </td>
        <td
          align="left"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          iPhone recommended devices are iPhone 6s and later, running the latest iOS versions. On iPhone, use safari as your browser. Do not use DuckDuckGo or Chrome.
        </td>
      </tr>
      <tr>
        <td
          width="20"
          align="center"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          &bull;
        </td>
        <td
          align="left"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          Android recommended devices are devices from reputable manufacturers like Samsung and Google, running the latest supported version of Android. Recommended browser is Google Chrome.
        </td>
      </tr>
      <tr>
        <td
          width="20"
          align="center"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          &bull;
        </td>
        <td
          align="left"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          Use a phone that doesn't have a cracked screen.
        </td>
      </tr>
      <tr>
        <td
          width="20"
          align="center"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          &bull;
        </td>
        <td
          align="left"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          When asked, please make sure to feedback any problems during testing.
        </td>
      </tr>
      <tr>
        <td
          width="20"
          align="center"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          &bull;
        </td>
        <td
          align="left"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          At the top right of the first page, select language (English, Swedish, Spanish).
        </td>
      </tr>
      <!-- <tr>
        <td
          width="20"
          align="center"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          &bull;
        </td>
        <td
          align="left"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          For more information and supported devices.
          <a
            style=" text-decoration: underline"
            href="https://capaciotalent.com/faq"
            target="_blank"
          >Read the FAQ </a>
        </td>
      </tr> -->
    </table>
    <br>
    <p
      class="border-none"
      style="font-size: 14px;"
    >
      Good luck and enjoy!
    </p>
    <br>
    <p
      v-if="!modal"
      class="border-none"
      style="font-size: 14px;"
    >
      Press <a
        class="link border-none"
        :href="link"
      >here</a> to open the test or paste this link into your browser: <br><a
        class="link border-none"
        :href="link"
      >{{ link }}</a>
    </p>
    <br>
    <p
      v-if="!modal"
      class="border-none"
      style="font-size: 14px;"
    >
      Or scan the QR code to open the test directly from your phone.
    </p>
    <img :src="qrCode">
    <br>
    <p
      class="border-none"
      style="font-size: 14px;"
    >
      Kind regards
      <br>
      Team Capacio
    </p>
  </div>

  <!-- Email for personality ++ -->
  <div
    v-else
    contenteditable="true"
    class="p-4 bg-white rounded shadow-lg"
  >
    <p
      class="border-none"
      style="margin-bottom:20px;font-size: 14px;"
    >
      <strong class="border-none">Instructions for questionnaire Personality++</strong>
    </p>
    <p
      class="border-none"
      style="margin-bottom:20px;font-size: 14px;"
    >
      This is a set of scientifically validated questionnaires with six modules, 71 questions, and takes less than 20 minutes to complete. Areas included are:<br>
      Big five personality traits, Empathy, Focus on detail, Impulsivity, Social style and Emotional regulation. Factors that tell a lot about a person.
    </p>

    <table
      cellspacing="0"
      cellpadding="0"
      border="0"
      style="border:0;"
    >
      <tr>
        <td
          width="20"
          align="center"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          &bull;
        </td>
        <td
          align="left"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          Make sure you have plenty of time to complete the questions in peace and quiet
        </td>
      </tr>
      <tr>
        <td
          width="20"
          align="center"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          &bull;
        </td>
        <td
          align="left"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          If any problems occur, note and email support@capacio.com.
        </td>
      </tr>
      <tr>
        <td
          width="20"
          align="center"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          &bull;
        </td>
        <td
          align="left"
          valign="top"
          style="font-size:14px; border: 0;"
        >
          You can select language at the start-up (English or Swedish)
        </td>
      </tr>
    </table>
    <br>

    <p
      v-if="!modal"
      class="border-none"
      style="font-size: 14px;"
    >
      Press <a
        class="link border-none"
        :href="link"
      >here</a> to open the questionnaire or paste this link into your browser: <br><a
        class="link border-none"
        :href="link"
      >{{ link }}</a>
    </p>
    <br>
    <p
      v-if="!modal"
      class="border-none"
      style="font-size: 14px;"
    >
      Or scan the QR code to open the test directly from your phone.
    </p>
    <img :src="qrCode">
    <br>
    <p
      class="border-none"
      style="font-size: 14px;"
    >
      Kind regards<br>
      Team Capacio
    </p>
  </div>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      required: true
    },
    modal: {
      type: Boolean,
      default: false
    },
    qrCode: {
      type: String,
      required: false,
      default: ''
    },
    isQuestionnaire: Boolean
  }
}
</script>
