<template>
  <div
    class="collpase-pane z-20 border-2 collapse rounded-box collapse-arrow my-5"

    :class="{'collapse-close': !openCollapse, 'collapse-open': openCollapse, 'overflow-visible': openCollapse, 'open-col' : openCollapse}"
  >
    <div
      class="flex cursor-pointer collapse-title justify-between"
      @click="openCollapse = !openCollapse"
    >
      <div class="flex space-x-10 flex-wrap content-center">
        <div
          class="px-4 text-xl font-medium"
          :class="{ 'open-col-title': openCollapse}"
        >
          {{ campaign.name }}
          <div
            v-if="campaign.new"
            class="badge badge-outline badge-success badge-lg"
          >
            New
          </div>
        </div>
      </div>
      <div class="flex space-x-10 mr-20">
        <router-link
          class="btn-tertiary z-50"
          :to="{name: 'Analytics', params: {campaignId: this.campaign.id}}"
        >
          View campaign results
        </router-link>
      </div>
    </div>
    <div
      class="flex justify-center p-0 m-0 collapse-content"
      :class="{'overflow-visible mb-0 pb-0 open-padding': openCollapse}"
    >
      <div
        class="flex flex-col justify-center w-full"
      >
        <div class="h-full bg-white p-10 rounded-b-xl">
          <div
            v-for="battery in sortedBatteries"
            :key="battery.id"
          >
            <div class="main-campaign-administration">
              <div class="flex items-center mt-4">
                <h4 class="mr-5 text-lg font-semibold black-cam">
                  {{ battery.name }}
                </h4>
                <div class="flex items-center time">
                  <img
                    src="@/assets/capacio/camp/clock.svg"
                    class="w-6 mr-2"
                    alt=""
                  >
                  <p class="mx-1 text-sm font-normal black-cam2">
                    {{ checkTime(battery.name) }}
                  </p>
                </div>
              </div>
              <div class="flex items-center justify-between mb-8">
                <p>{{ titleToDescription[battery.name] }}</p>

                <link-component

                  :is-questionnaire="false"
                  :test-battery-id="battery.id"
                />
              </div>
              <hr class="my-4 hr-line">
            </div>
          </div>
          <div
            v-for="battery in campaign.QuestionnaireBatteries"
            :key="battery.id"
          >
            <div class="main-campaign-administration">
              <div class="flex items-center">
                <h4 class="mr-3 text-lg font-semibold black-cam">
                  Personality++
                </h4>
                <img
                  src="@/assets/capacio/camp/clock.svg"
                  alt=""
                >

                <p class="mx-1 mb-0 text-sm font-normal black-cam2">
                  10-15 mins
                </p>
              </div>
              <div class="flex items-center justify-between">
                <p>This validated questionnaire captures several aspects of a person’s traits/characteristics.</p>
                <link-component
                  :is-questionnaire="true"
                  :test-battery-id="battery.id"
                />
              </div>
              <hr class="my-4 hr-line">
            </div>
          </div>
          <div class="flex items-center justify-between pt-5">
            <label
              for="instructions-modal"
              class="z-20 flex items-center text-xs cursor-pointer link modal-button"
            >
              <svg
                class="mr-4"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.63313 10.465C6.43979 10.465 6.28313 10.3083 6.28313 10.115V6.0105C6.28313 5.81716 6.43979 5.6605 6.63313 5.6605H7.36267C7.55601 5.6605 7.71267 5.81716 7.71267 6.0105V10.115C7.71267 10.3083 7.55601 10.465 7.36267 10.465H6.63313Z"
                  fill="#101317"
                />
                <path
                  d="M7.00126 4.64601C6.78874 4.64601 6.60639 4.57553 6.45428 4.43459C6.30434 4.29141 6.22944 4.12026 6.22944 3.92115C6.22944 3.72428 6.30434 3.55536 6.45428 3.41442C6.60639 3.27123 6.78874 3.19965 7.00126 3.19965C7.21378 3.19965 7.39501 3.27123 7.54488 3.41442C7.69706 3.55536 7.77308 3.72428 7.77308 3.92115C7.77308 4.12026 7.69706 4.29141 7.54488 4.43459C7.39501 4.57553 7.21378 4.64601 7.00126 4.64601Z"
                  fill="#101317"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14 7C14 10.866 10.866 14 7 14C3.134 14 0 10.866 0 7C0 3.134 3.134 0 7 0C10.866 0 14 3.134 14 7ZM12.6 7C12.6 10.0928 10.0928 12.6 7 12.6C3.9072 12.6 1.4 10.0928 1.4 7C1.4 3.9072 3.9072 1.4 7 1.4C10.0928 1.4 12.6 3.9072 12.6 7Z"
                  fill="#101317"
                />
              </svg>

              Candidate instructions
            </label>
            <router-link
              class="btn-primary-small z-50"
              :to="{name: 'Analytics', params: {campaignId: this.campaign.id}}"
            >
              View campaign results
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import LinkComponent from './LinkComponent.vue'

export default {
  components: {
    LinkComponent
  },
  props: {
    openLatest: {
      type: Boolean
    },
    campaign: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      openCollapse: false,
      titleToDescription: {
        'Full Cognitive Profile': 'This test gives a complete cognitive profile. It gives a full view of an individual’s cognitive capacity.',
        'Gather & Process': 'Measure different aspects of focus and attention.',
        'Active Sketchpad': 'Measure different aspects of memory and the ability to hold information on line while working with it.',
        'Monitor & Control': 'Measure the ability to switch between tasks, find new solutions and to regulate impulses.',
        Envision: 'Measure the ability to sort, plan, organize and find the shortest way to a given target.'
      },
      sortedBatteries: []
    }
  },
  methods: {
    createLink (id, isQuestionnaire) {
      return (isQuestionnaire) ? `${window.location.origin}/questionnaire/invite/${id}` : `${this.$APP_FRONTEND_URL}/invitation/${id}`
    },
    checkTime (value) {
      let result = ''
      if (value === 'Full Cognitive Profile') result = '45 mins'
      else result = '10 -15 mins'
      return result
    },
    sortBatteries (batteries) {
      if (batteries[0].name === 'Full Cognitive Profile') {
        return batteries
      }
      const order = ['Gather & Process', 'Active Sketchpad', 'Monitor & Control', 'Envision']
      const sortedBatteries = []
      for (const name of order) {
        batteries.map((item) => {
          if (item.name === name) {
            sortedBatteries.push(item)
          }
        })
      }
      for (const battery of batteries) {
        if (!order.includes(battery.name)) {
          sortedBatteries.push(battery)
        }
      }
      return sortedBatteries
    }
  },
  created () {
    this.sortedBatteries = this.sortBatteries(this.campaign.TestBatteries)
  },
  mounted () {
    // if campaign is the newest campaign
    if (this.openLatest) {
      this.openCollapse = true
    }
  }
}
</script>

<style>
@import "../overview.scss";
</style>
